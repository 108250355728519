/* eslint-disable camelcase */
/* eslint-disable complexity */
import React from 'react'
import moment from 'moment'
import 'moment/locale/es'
import styles from './Accept.module.css'
import messages from './Accept.messages'
import apiAcuity from '../../Api/Acuity'
import LoaderSection from '../LoaderSection/LoaderSection'
import implant from '../../Assets/images/svg/implant.svg'
import arrow from '../../Assets/images/svg/arrowBack.svg'
import clock from '../../Assets/images/svg/clock.svg'
import TimeCounter from '../TimeCounter/TimeCounter'
import TestimonialsCarousel from '../Carousel'
import testimony from '../../Utils/testimonials'
import Utils from '../../Utils/utils'

/**
 * Accept Component
 * @return {void}
 */
class Accept extends React.Component {
  /**
   * Constructor
   * @param {*} props .
   */
  constructor(props) {
    super(props)
    this.state = {
      expiredTime: false,
      loader: false,
      error: false
    }
  }

  backComponent = () => {
    const { nextStep } = this.props
    return (
      <div
        role="button"
        tabIndex={0}
        className={styles.Back}
        onClick={() => nextStep(true)}
      >
        <img
          alt="logo"
          src={arrow}
        />
        <p>{messages.back}</p>
      </div>
    )
  }

  branchChoose = () => {
    const { branch, dateSelected, country } = this.props
    const {
      Center_Name, Street, Number, Neighborhood, Appointment_Type_Id
    } = branch
    const address = `${Street} ${Number}, ${Neighborhood}`
    const date = moment(dateSelected).format('dddd DD MMMM')
    const time = Utils.getDateByCountryTimeZone(dateSelected, country, Appointment_Type_Id)

    return (
      <div
        className={styles.CenterContainer}
        key={branch.Appointment_Type_Id}
      >
        <div
          className={styles.Item}
        >
          {branch.Center_Icon ? (
            <img
              className={styles.Icon}
              alt="icon"
              src={branch.Center_Icon}
            />
          ) : (
            <img
              className={styles.Icon}
              alt="icon"
              src={implant}
            />
          )}
          <div className={styles.AddressContainer}>
            <p className={styles.Center}>{Center_Name}</p>
            <p className={styles.Address}>{address}</p>
          </div>
        </div>
        <div
          className={styles.ItemSecond}
        >
          <img
            className={styles.Icon}
            alt="icon"
            src={clock}
          />
          <div className={styles.InfoDate}>
            <p className={styles.Dates}>{date}</p>
            <p className={styles.Address}>{time}</p>
          </div>
        </div>
      </div>
    )
  }

  setExpired = () => {
    this.setState({ expiredTime: true })
  }

  submitData = async () => {
    this.setState({ loader: true })
    const { expiredTime } = this.state
    const {
      dateSelected, branch, userData, setAppointment, customerId, publicKey, onsite, partner
    } = this.props
    const id = branch.Appointment_Type_Id
    if (expiredTime) {
      return ''
    }
    let journey = onsite ? onsite : false

    const appointment = await apiAcuity.appointments(
      dateSelected,
      id,
      userData,
      customerId,
      publicKey,
      partner,
      journey
    )

    if (appointment && appointment.id) {
      setAppointment && setAppointment(appointment)
    } else {
      this.setState({ error: messages.error, loader: false })
    }
    return ''
  }

  /**
   * Render
   * @returns {void}
   */
  render() {
    const { expiredTime, loader, error } = this.state
    const { userData } = this.props
    const { firstName } = userData
    const welcome = messages.welcome.replace('{Name}', firstName)

    return (
      <div className={styles.ContainerAccept}>
        {this.backComponent()}
        <p className={styles.TitleName}>{welcome}</p>
        {this.branchChoose()}
        <TimeCounter setExpired={this.setExpired} />
        {loader
          ? (
            <div className={styles.ContainerLoader}>
              <LoaderSection />
            </div>
          ) : !expiredTime
            && (
              <>
                <div
                  role="button"
                  tabIndex={0}
                  className={styles.SubmitButton}
                  onClick={() => this.submitData()}
                >
                  {messages.buttonText}
                </div>
                <p className={styles.Error}>{error}</p>
              </>
            )
        }
        <TestimonialsCarousel info={testimony} />
      </div>
    )
  }
}

export default Accept
